<template>
  <v-container>
    <div class="primary-font fw600 f18 mb-5">
      Course Outline
    </div>

    <div v-for="(item) in course.modules" :key="item.id" class="mb-5 primary-font">
      <section class="description_div l-primary--text fw600 f16">
        {{item.title ? item.title : 'Untitled' }} 
      </section>
      <!-- <section v-if="item.description" class="description_div f12 mt-1 secondary-2--text" v-html="item.description"></section> -->
      <section v-if="item.valid_until !== 'anytime'" class="f13">
        SCHEDULE: {{ $dateFormat.mmDDyy(item.valid_until) }}
      </section>
    </div>
    <div v-if="course.modules.length === 0" class="fw600 f16 secondary--text"><i>No Modules Available</i></div>
  </v-container>
</template>
  
<style scoped>
  .description_div {
    white-space: normal !important;
    word-wrap: break-word !important;
  }

  p {
    margin-bottom: 5px !important;
  }
</style>
  
<script>
  export default {
    name: 'course-timeline',
    props: ['course'],
  }
  </script>